import React, { useState, useContext, useEffect, useCallback, useRef } from 'react';
import * as faia from "./animatingColumnsOrderingWithFlip.module.css"
import { NotInViewContext } from './AnimateScrollIntoViewport';
import { useInView } from "framer-motion"
import FlipAnimatedBetweenAnimationKeys from './FlipAnimatedBetweenAnimationKeys';
import AnimationButton from './AnimationButton';
import AnimationCycleTimeSlider from './AnimationCycleTimeSlider';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

interface Props {
    children: React.ReactNode;
    transformSquare?: string;
    squareClass?: string;
    styleBlank?: React.CSSProperties;
}

const AnimatingColumnsOrderingWithFlip = ({ children, transformSquare = '', squareClass = "", styleBlank = {} }: Props) => {
    const { isCountedTwice } = useContext(NotInViewContext);
    const ref = useRef(null);
    const isInView = useInView(ref);
    const refIntervalsCount = useRef(0);
    const refIntervalId = useRef<number | null>(null);
    const [animation, setAnimation] = useState(false);
    const [isClicked, setIsClicked] = useState(false);
    const [timeInterval, setTimeInterval] = useState(3);

    const generatingColumnsOrder = useCallback(() => {
        refIntervalsCount.current += 1;
        const tick = refIntervalsCount.current % 5;
        const orders = [
            [1, 2, 3, 4, 5],
            [4, 2, 3, 5, 1],
            [1, 5, 3, 2, 4],
            [2, 1, 5, 4, 3],
            [5, 3, 4, 2, 1],
        ];
        return orders[tick] || orders[0];
    }, []);

    const [columnsOrder, setColumnsOrder] = useState(generatingColumnsOrder());

    useEffect(() => {
        if (!isInView || !animation) {
            if (refIntervalId.current) {
                clearInterval(refIntervalId.current);
                refIntervalId.current = null;
            }
            return;
        }

        const intervalId = setInterval(() => {
            setColumnsOrder(generatingColumnsOrder());
        }, timeInterval * 1000);

        refIntervalId.current = intervalId;

        return () => clearInterval(intervalId);
    }, [isInView, animation, timeInterval, generatingColumnsOrder]);

    const handleToggleAnimationPlay = useCallback(() => {
        setIsClicked(true);
        setAnimation(prev => !prev);
    }, []);

    return (
        <div className={`${faia.faiaWrap} flip`} ref={ref}>
            <div className={faia.grid}>
                {columnsOrder.map((id) => (
                    <FlipAnimatedBetweenAnimationKeys duration={timeInterval * 1000}
                        transformSquare={transformSquare}
                        animationKey={columnsOrder}
                        key={id}
                    >
                        {id === 'blank' ? (
                            <div className={`${faia.blank} blank`} style={styleBlank} />
                        ) : (
                            <div className={`${faia.square} square ${squareClass}`}
                                style={transformSquare ? { transform: transformSquare } : {}}>
                                {children}
                                {id}
                            </div>
                        )}
                    </FlipAnimatedBetweenAnimationKeys>
                ))}
            </div>
            <AnimationButton handleClickStartStopAnimation={handleToggleAnimationPlay}>
                <p>Presuny</p>
                {animation ? <StopCircleIcon /> : <PlayCircleOutlineIcon />}
            </AnimationButton>
            <AnimationCycleTimeSlider duration={timeInterval} setDuration={setTimeInterval} title='Interval presunov' />
        </div>
    );
}

export default AnimatingColumnsOrderingWithFlip;
